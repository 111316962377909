<template>
  <!--components/product4/product4.wxml-->
  <!--有秒杀火焰-->
  <div class="detail-box">
    <div class="left">
      <img class="full-img" mode="widthFix" src="~images/daoyou2.png">
    </div>
    <div class="right">
      <div class="title-box">
        标题28px中黑体#191919主标题28px中黑体#191919
      </div>
      
      <div class="price-box">
        <img class="bt-img" mode="heightFix" src="~images/fire.png">
        <div class="price flexcenter">
          <div class="l1">
            ￥268
            <div class="yj">原价 ￥298</div>
          </div>
          <div class="r1">立即秒杀</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'product4',
    props: {
      msg: String,
      isShowChose: {
        type: Boolean,
        default: false,
      },
      bordernone: {
        type: Boolean,
      }
    },
    data() {
      return {
        value: 1,
      }
    },
    methods: {
      onChange(event) {
        console.log(event.detail);
      },
    }
  }
</script>

<style scoped>
  /* components/product4/product4.wxss */
  .full-img {
    display: block;
    width: 100%;
  }
  .flexcenter {
    display: flex;
    align-items: center;
  }
  
  .detail-box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    padding-bottom: 10px;
    border-bottom: 1px solid #EEEEEE;
  }
  .left {
    margin-right: 20px;
    width: 200px;
  }
  .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .right .title-box {
    width: 470px;
    font-size: 28px;
    font-weight: bold;
    color: #191919;
  }
  
  .right .price-box {
    position: relative;
    width: 360px;
    height: 74px;
    border-radius: 8px;
    background-image: linear-gradient(180deg, #FCEC2D 0%, #F8D414 100%);
  }
  .bt-img {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    height: 74px;
  }
  .right .price-box .price {
    position: relative;
    z-index: 2;
    justify-content: space-between;
    height: 74px;
    text-align: center;
  }
  .right .price-box .price .l1 {
    width: 50%;
    line-height: 1;
    font-size: 32px;
    font-weight: bold;
    color: #FFFFFF;
  }
  .right .price-box .price .l1 .yj {
    font-size: 22px;
    font-weight: normal;
    color: #FFD1A7;
    text-decoration: line-through;
  }
  .right .price-box .price .r1 {
    width: 50%;
    font-size: 24px;
    font-weight: bold;
    color: #895D16;
  }
</style>
